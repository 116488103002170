/**
 * ECCUBE 固有のスタイルユーティリティ
 */

@mixin borderTop(){
  border-top: 1px dotted #ccc;
}

@mixin borderBottom(){
  border-bottom: 1px dotted #ccc;
}

@mixin reset_link(){
  a{
    color: inherit;
    text-decoration: none;
  }
  a:hover{
    text-decoration: none;
  }
}
