@import "../mixins/projects";
@import "../mixins/variables";
@import "../mixins/media";
/*
マイページ

マイページで利用するためのスタイルガイド群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>


Styleguide 9.1
*/

/*
マイページ

マイページで表示するメニューリストです。

ul を利用したリスト要素で記述します。

ex [マイページ　メニューリスト部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-navlist

Styleguide 9.1.1
*/
.ec-navlistRole{
  & &__navlist {
    @include reset_link;
    display: flex;
    flex-wrap: wrap;
    border-color: #ccc;
    border-style: solid;
    border-width: 1px 0 0 1px;
    margin-bottom: 32px;
    padding: 0;
    list-style: none;
    @include media_desktop {
      flex-wrap: nowrap;
      margin-bottom: 50px;
    }
  }

  & &__item{
    width: 50%;
    border-color: #ccc;
    border-style: solid;
    border-width: 0 1px 1px 0;
    text-align: center;
    font-weight: 500;
    a {
      color: #000;
      letter-spacing: .07em;
      padding: 16px;
      width: 100%;
      display: inline-block;
      transition: background-color .35s ease, color .35s ease;

      @include hover() {
        background-color: #ff5917;
        color: #fff;
      }
    }
  }
  .active {
    a {
      color: #ff5917;
      font-weight: 700
    }
  }
}

/*
マイページ（お気に入り機能無効）

マイページで表示するメニューリストです。

ul を利用したリスト要素で記述します。

ex [マイページ　メニューリスト部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-navlist_noFavorite

Styleguide 9.1.2
*/

/*
Welcome メッセージ

マイページで表示するログイン名の表示コンポーネントです。

ex [マイページ　メニューリスト下部分](http://demo3.ec-cube.net/mypage)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-welcomeMsg

Styleguide 9.1.3
*/
.ec-welcomeMsg{
  @include mypageContainer;
  margin: 1em 0;
  padding-bottom: 32px;
  text-align: center;
  border-bottom: 2px dotted #999a9a;
  // @include borderBottom;

}

/*
お気に入り一覧

お気に入り一覧で表示するアイテムの表示コンポーネントです。

ex [マイページ　お気に入り一覧](http://demo3.ec-cube.net/mypage/favorite)

Markup:
include /assets/tmpl/elements/9.1.mypage.pug
+ec-favorite

Styleguide 9.1.4
*/
.ec-favoriteRole{
  & &__header {
    margin-bottom: 16px;
  }
  & &__detail {
  }
  & &__itemList {
    @include reset_link;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
  }
  & &__item{
    margin-bottom: 8px;
    width: 47.5%;
    position: relative;
    box-sizing: border-box;
    padding: 10px;
    &-image {
      // height: 150px;
      margin-bottom: 10px;
      padding: 100% 0 0;
      position: relative;
      text-align: center;
      // @include media_desktop() {
      //   height: 250px;
      // }
    }
    img{
      width: auto;
      max-height: 100%;
    }
    @include media_desktop(){
      width: 25%;
    }
    .ec-closeBtn--circle {
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 1;
      .ec-icon img{
        width: 1em;
        height: 1em;
      }
    }
  }
  & &__itemThumb {
    display: block;
    height:auto;
    margin-bottom: 8px;
    
    & img{
      bottom: auto;
      display: block;
      height: 100%;
      left: 0;
      max-height: none;
      object-fit: contain;
      object-position: center;
      position: absolute;
      right: auto;
      top: 0;
      width: 100%;
    }
  }
  & &__itemTitle{
    margin-bottom: 2px;
  }
  & &__itemPrice{
    font-weight: bold;
    margin-bottom: 0;
  }

}
