@import "../mixins/variables";
/*
アイコン

デフォルトテンプレートのアイコンは`.ec-icon`>`img`タグで使用することができます

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>

Markup:
include /assets/tmpl/elements/4.1.icon.pug
div(style="background-color: rgba(130,130,130,.15); padding: 20px;")
  +icon-all

Styleguide 4.1
*/
.ec-icon img {
  max-width: 80px;
  max-height: 80px;
}
