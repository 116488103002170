@import "../mixins/media";
@import "../mixins/projects";

/*
注文履歴一覧

マイページ 注文履歴部に関する Project コンポーネントを定義します。

ex [マイページ　注文履歴一覧](http://demo3.ec-cube.net/mypage)
(要ログイン)

Markup:
include /assets/tmpl/elements/16.1.history.pug
+ec-historyRole

Styleguide 16.1
*/
.ec-historyRole{
  & &__contents{
    padding-top: 1em;
    padding-bottom: 16px;
    border-top: 2px dotted #999a9a;
    display: flex;
    flex-direction: column;
    gap: 15px 60px;
    padding: 30px 0;
    // color: #525263;
    @include media_desktop {
      flex-direction: row;
      padding: 30px 0;
    }
  }
  & &__header{
    width: 100%;
    @include media_desktop {
      flex-grow: 0;
      flex-shrink: 0;
      width: 210px;
    }
  }
  & &__detail{
    // @include borderTop;
    width: 100%;

    .ec-imageGrid {
      align-items: center;
      border-top: 1px solid #ccc;
      display: flex;
      flex-flow: row nowrap;
      gap: 20px;
      padding: 15px 0 15px;

      @include media_desktop {
        gap: 30px;
      }

      &__img {
        align-items: center;
        background-color: rgba(#000000, .1);
        display: flex;
        flex-flow: row nowrap;
        height: 140px;
        justify-content: center;
        margin: 0;
        padding: 10px;
        text-align: center;
        width: 140px;
        
        @include media_desktop {
          flex-grow: 0;
          flex-shrink: 0;
          width: 180px;
        }

        @media (min-width: 992px) {
          width: 210px;
        }

        & img {
          max-height: 100%;
          object-fit: contain;
          width: 100%;
        }
      }

      &__content {
        font-size: 1.4rem;
        font-weight: bold;
        letter-spacing: .06em;
        
        @include media_desktop {
          flex-grow: 1;
          flex-shrink: 1;
          font-size: 1.8rem;
        }

        @media (min-width: 992px) {
          font-size: 2.0rem;
        }
      }
    }

    .ec-imageGrid:nth-of-type(1) {
      @include media_desktop {
        border-top: none;
        padding-top: 0;
      }
    }

    .ec-historyRole__detailTitle {
      margin-bottom: 0;
      font-size: 1.6rem;
      font-weight: bold;
      letter-spacing: .06em;

      @include media_desktop {
        font-size: 2.0rem;
      }

      @media (min-width: 992px) {
        font-size: 2.4rem;
      }
    }

    .ec-historyRole__detailPrice {
      margin-bottom: 0;
      font-size: 1.6rem;
      font-weight: bold;
      letter-spacing: .06em;

      @include media_desktop {
        font-size: 2.0rem;
      }

      @media (min-width: 992px) {
        font-size: 2.4rem;
      }
    }

    @include media_desktop {
      flex-grow: 1;
      flex-shrink: 1;
      width: auto;
      border-top: none;
    }

    &__action {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      gap: 10px 16px;
      margin: 20px 0 0;

      @include media_desktop {
        flex-direction: column;
      }

      &__item {
        width: calc(50% - 8px);
        @include media_desktop {
          width: 100%;
        }

        .com-button {
          box-shadow: none;
          height: 40px;
          max-width: 100%;
          padding-left: 0;
          padding-right: 0;

          @include media_desktop {
            height: 44px;
          }

          &:after {
            display: none;
          }
        }
      }
    }
  }
}

/*
注文履歴一覧 規格

マイページ 注文履歴内アイテムの規格を定義します。

ex [マイページ　注文履歴一覧](http://demo3.ec-cube.net/mypage)
(要ログイン)

Markup:
include /assets/tmpl/elements/16.1.history.pug
+ec-historyRole-option

Styleguide 16.1.1
*/

.ec-historyRole{
  & &__detail {
    .ec-historyRole__detailOption {
      display: inline-block;
      margin-bottom: 8px;
      margin-right: .5rem;
      font-size: 1.6rem;
    }
    .ec-historyRole__detailOption::after {
      display: inline-block;
      padding-left: .5rem;
      content: "/";
      font-weight: bold;
    }
  }
}

/*
注文履歴一覧ヘッダ

注文履歴一覧で使用するヘッダのコンポーネントを定義します。

ex [マイページ　注文履歴一覧ヘッダ](http://demo3.ec-cube.net/mypage)
(要ログイン)

Markup:
include /assets/tmpl/elements/16.1.history.pug
+ec-historyHeader
p hofe

Styleguide 16.1.2
*/


.ec-historyListHeader{
  & &__date{
    font-weight: bold;
    font-size: 16px;
    margin: 0;
    @include media_desktop {
      font-weight: bold;
      font-size: 18px;
    }
  }
  .ec-definitions {
    dt,dd {
      font-size: 16px;
      font-weight: 700;
      @include media_desktop {
        font-weight: 700;
        font-size: 18px;
      }
    }
  }

  & &__action{
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    gap: 10px 16px;
    margin: 16px 0 0;

    @include media_desktop {
      flex-direction: column;
    }

    &__item {
      width: calc(50% - 8px);
      @include media_desktop {
        width: 100%;
      }

      .com-button {
        font-size: 1.4rem;
        box-shadow: none;
        height: 40px;
        max-width: 100%;

        @include media_desktop {
          font-size: 1.6rem;
          height: 44px;
        }
      }

      &--dl .com-button {
        gap: 10px;
        padding-left: 0;
        padding-right: 0;

        &:before {
          background-color: #ff5917;
          content: '';
          display: block;
          height: 20px;
          margin: 0;
          mask-image: url(../images/icons/icon-black-download.svg);
          mask-position: center;
          mask-repeat: no-repeat;
          mask-size: contain;
          transition: background-color .35s ease;
          width: 20px;
        }

        &:after {
          display: none;
        }

        @include hover() {
          &:before {
            background-color: #fff;
          }
        }
      }
    }


    // a {
    //   font-size: 12px;
    //   font-weight: normal;
    //   @include media_desktop {
    //     font-size: 14px;
    //   }
    // }
  }
}
